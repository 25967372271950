import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Masthead from '../components/masthead/masthead'
import BlogListing from '../components/bloglisting/bloglisting'
import { graphql } from 'gatsby'

export const query = graphql`
  {
    prismic {
      allBlogposts(sortBy: date_DESC) {
        edges {
          node {
            title
            summary
            _meta {
              id
              uid
              tags
              type
            }
            image
            date
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allBlogposts.edges
  if (!doc) return null
  return (
    <Layout>
      <SEO
        title="Blog"
        description="All the latest news stories from Scoot Science"
        pathname="/blog"
      />
      <Masthead title="Blog" cssClass="c-masthead--condensed" hidefooter></Masthead>
      <BlogListing doc={doc} />
    </Layout>
  )
}
