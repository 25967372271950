import React from 'react'
import { Date, RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'
import Link from 'gatsby-plugin-transition-link'
import IsVisible from 'react-is-visible'
import { window, document } from 'browser-monads'

function handleCategoryChange (category) {
  let categoryItem = document.querySelector('[data-category=' + category + ']')
  if (categoryItem !== null) {
    let categoryItems = document.querySelectorAll('[data-category]')
    let items = document.querySelectorAll('[data-item-category]')
    categoryItems.forEach(function (item) {
      item.classList.remove('is-current')
    })
    categoryItem.classList.add('is-current')
    if (category === 'All') {
      items.forEach(function (item) {
        item.classList.remove('is-hidden')
      })
    } else {
      items.forEach(function (item) {
        item.classList.add('is-hidden')
      })
      let categoryItems = document.querySelectorAll('[data-item-category=' + category + ']')
      categoryItems.forEach(function (item) {
        item.classList.remove('is-hidden')
      })
    }
  }
}

class BlogListing extends React.Component {
  componentDidMount () {
    window.addEventListener('hashchange', function (e) {
      let category = window.location.hash ? window.location.hash.substring(1) : 'All'
      handleCategoryChange(category)
    })
    if (window.location.hash) {
      let category = window.location.hash.substring(1)
      handleCategoryChange(category)
    }
  }
  categoryClick (event) {
    let category = event.currentTarget.dataset.category
    if (category === 'All') {
      window.location.hash = ''
      history.pushState('', document.title, window.location.pathname)
    } else {
      window.location.hash = category
    }
  }
  render () {
    const doc = this.props.doc
    let categories = []
    let categoryName = ''
    return (
      <div className="c-blog-listing">
        <div className="container-fluid">
          <div className="row">
            <div className="c-blog-listing__header">
              {doc.map((category, index) => {
                let categoryName = category.node._meta.tags[0]
                categories.push(categoryName)
              })}
              {categories.length > 0 && (
                <ul className="c-blog-listing__categories">
                  <li className="c-blog-listing__categories-item">
                    <button className="c-blog-listing__categories-btn is-current" data-category="All" onClick={this.categoryClick}>
                      <span>All</span>
                      <span>{categories.length}</span>
                    </button>
                  </li>
                  {
                    categories.sort().map((category, index) => {
                      if (categoryName === category || category === undefined) return null
                      categoryName = category
                      return (
                        <li className="c-blog-listing__categories-item" key={ `category-${ index }` }>
                          <button className="c-blog-listing__categories-btn" data-category={categoryName} onClick={this.categoryClick}>
                            <span>{categoryName}</span>
                            <span>{categories.filter(v => (v === categoryName)).length}</span>
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
              )}
              {categories.length === 0 && (
                <p>We are busy writing some blog posts, please come back later.</p>
              )}
            </div>
          </div>
          <div className="row">
            {doc.map((post, index) => {
              if (post === null) return null
              // Store and format the blog post's publication date
              let postDate = Date(post.node.date)
              let categoryName = post.node._meta.tags[0]
              postDate = postDate
                ? new Intl.DateTimeFormat('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric'
                }).format(postDate) : ''
              return (
                <div className="c-blog-listing__item" key={`post-${ index }`} data-item-category={categoryName}>
                  <Link
                    to={linkResolver(post.node._meta)}
                    entry={{ delay: 0.5, length: 0 }}
                    exit={{ length: 0.5 }}
                    className="c-blog-listing__item-link"
                  >
                    <h2 className="c-blog-listing__item-title">{RichText.asText(post.node.title)}</h2>
                    <p className="c-blog-listing__item-summary">{RichText.asText(post.node.summary)}</p>
                    <span className="c-blog-listing__item-date">{postDate}</span>
                    <span className="c-blog-listing__item-category">{categoryName}</span>
                    {post.node.image && (
                      <IsVisible once>
                        { isVisible =>
                          <div className={`c-blog-listing__item-image${ isVisible ? ' is-visible' : '' }`}>
                            <img src={ post.node.image.low.url } data-src={ post.node.image.url } className="lazyload" alt=""/>
                          </div>
                        }
                      </IsVisible>
                    )}
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default BlogListing
